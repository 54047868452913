@media only screen and (max-width: 1024px) {

    .headline-wrapper p {
        font-size: 6rem;

    }

}


@media only screen and (max-width: 1024px) {

    .headline-wrapper p {
        font-size: 6rem;
    }

}

@media only screen and (max-width: 768px) {

    .headline-wrapper p {
        font-size: 5vh;

    }

}

@media only screen and (max-height: 950px) {

    .headline-wrapper p {
        font-size: 5rem;

    }
    .experience-item  {
        margin-bottom: 1rem;
    }
    .experience-container h2 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 2em;
    }
    .experience-container h3 {
        margin-top: 0;
        margin-bottom: .5rem;
    }
    .experience-container p {
        margin-bottom: 1rem;
        font-size: 1.2em;
    }
}


@media only screen and (max-height: 640px) {
    .headline-wrapper p {
        font-size: 4em;
     }
}


@media only screen and (max-height: 1030px) {
    .about-me-text p {
        font-size: 1.2em;
        margin-top: 0.5em;

    }
    .about-me-text h2 {
        font-size: 2.5em;
    }
    .about-me-text {
        padding: 0 1.5em .5em;
    }
}

@media only screen and (max-height: 800px) {
    .about-me-text p {
        font-size: .9em;
    }
    .about-me-text h2 {
        font-size: 1.7em;
    }
}



