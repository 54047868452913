* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background-color: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: var(--color-grey);
    color: #ffffff;
    font-family: var(--font-family);
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
    transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover,
button:focus {
    background: var(--color-grey-shade);
}


button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

button:hover {
    color: var(--color-yellow);
    cursor: pointer;
}

.link-effect {
    text-decoration: none;
    transition: all 300ms ease-in-out;
    transition-delay: 0s;
    border-bottom: 0 solid var(--color-mauve);
}

.link-effect:hover {
    cursor: pointer;
    color: white;
    box-shadow: inset 0 -1.2em 0 var(--color-mauve);
    transition-duration: 100ms;

}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-mauve);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--color-mauve);
}


h1 {
    color: var(--color-yellow);
    font-size: 4em;
}

h2 {
    color: white;
    font-size: 3vw;
    font-family: var(--font-family);
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
}


input, textarea, select {
    font-family: var(--font-family);
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

