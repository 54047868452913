.about-me-section {
    display: flex;
    font-family: var(--font-family);
    height: 120vh;
    /*background-image: url("http://localhost:3000/computer_background.jpg");*/
    background-image: url("/static/media/computer_background.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;

}


.about-me-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
}


.about-me-text {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    padding: 5rem 5rem 5rem 5rem;
    font-family: var(--font-family-text);
    font-weight: lighter;
    line-height: 35px;
    color: white ;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    animation: blur-in 500ms 200ms forwards;
    border: 1px solid rgba(255, 255, 255, 0.3);

}


.text-block {
    padding: 5rem;
}


.opacity-transition-out {
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

.about-me-content {
    display: grid;
    grid-template-rows: auto auto auto;
    height: 100%;
    width: 100%;
    font-family: var(--font-family);
    margin-right: 1em;
    margin-left: 1em;
}

.content-and-headline {
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 2 / 3;
    height: 100%;
    width: 100%;
    font-family: var(--font-family);

}

#content-box {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.icons {
    margin-right: 20px;
    margin-bottom: 10px;
    grid-row: 2;
    margin-top: 1rem;
    align-self: start;
}

.icon {
    border: 1px solid var(--color-grey-shade);
    padding: 3px 6px 0 6px;
    color: var(--color-mauve);
    font-size: 4vh;
    margin-right: 6px;
    cursor: pointer;
}

.icon:hover {
    color: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}


.sidebar {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
}


.about-me-image img {
    height: 25vw;
}


.content-headline {
    font-size: 5vw;
    color: white;
    height: 15vh;
    padding-top: 1rem;
    padding-left: 5rem;
    border-top:  1px solid white;
    z-index: 20;
    font-weight:lighter;

}


.menu {
    display: flex;
    grid-row: 1;
    flex-direction: row;
    align-items: end;
}

.menu-element {
    background-color: var(--color-grey);
    color: white;
    font-family: var(--font-family);
    font-size: calc(1.5vw + .7vh);
    font-weight: lighter;
    width: 20%;
    padding-right: 2vw;
    padding-left: .5vw;
    padding-bottom: 5px;
    height: 90%;
}

.menu-active {
    height: 100%;
    color: white;
    font-weight: normal;
    box-shadow: 3px 3px 5px 0px var(--color-grey);

}

.headline-wrapper {
    display: grid;
    height: 100%;
    width: 10vw;
    justify-content: start;
}

.headline-wrapper p {
    font-size: 10vh;
    text-orientation: upright;
    writing-mode: vertical-rl;
    text-transform: uppercase;

}

.headline {
    font-weight: lighter;
    color: white;
    height: 100%;
}

.headline-background {
    grid-row: 2;
    grid-column: 1;
    height: 15vh;
    z-index: 0;
    opacity: .5;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: blur-in 500ms 200ms forwards;

}


.me {
    color: var(--color-mauve);
    font-weight: lighter;
}



.opacity-transition-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;

}


.menu-background-1 {
    background-color: var(--color-menu-1);

}

.menu-background-2 {
    background-color: var(--color-menu-2);
}

.menu-background-3 {
    background-color: var(--color-menu-3);
}

.about-me-text p {
    margin-top: 2rem;
    font-weight: lighter;
    font-size: 1.5rem;
}
