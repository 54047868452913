#contact {
    margin-top: 15vh;
    display: flex;
    justify-items: center;
    justify-content: center;
    color: var(--color-text);
    font-family: var(--font-family);
    background-color: white;
    height: 80vh;
}

label {
    margin-bottom: 1vh;
    margin-top: 1vh;
}

textarea {
    height: 40vh;
    width: 40vw;
    resize: none;
    font-size: 1em;
    margin-bottom: 1vh;
    font-family: var(--font-family);
    padding: 5px 5px 5px 5px;

}

#contact input{
    width: 40vw;
    background-color: white;
    border: 1px solid var(--color-grey);
    color: var(--color-text);
    font-size: 1em;
}

#contact-form {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 10vh;
}

.thank-you-div {
    font-family: var(--font-family);
    margin-top: 10vh;
    margin-bottom: 10vh;
    font-size: 5vh;
    align-self: center;
    color: black;
}

