@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

:root {
    --font-family: Work Sans, sans-serif;
    --font-family-text: Roboto;
    --color-bg: white;
    --color-mauve: #b8a89a;
    --color-text: #000000;
    --color-grey: #2e2e30;
    --color-grey-shade: #989898;
    --color-dark-lila: #5E4B5E;
    --color-light-lila: #A16477;
    --color-yellow: #ffc155;

    --color-chess-white: #f0d9b5;
    --color-chess-black: #b58863;

    --color-chess-take: #9C4A59;
    --color-chess-legal: #CED570;
    --color-chess-last-move: #ffc155;
    --color-chess-hovered: #F2FEDC;

    --color-menu-1: #5E4B5E;
    --color-menu-2: #A16477;
    --color-menu-3: #DF8576;


}