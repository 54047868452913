
.avatar img {
    height: 5vh;
}

.black {
    background-color: var(--color-chess-black);
    color: var(--color-chess-white);
}

.white {
    background-color: var(--color-chess-white);
    color: var(--color-chess-black);

}

select {
    background: var(--color-grey);
    color: #fff;
    text-align: center;
    font-size: 2vh;
    border: 1px solid var(--color-grey);
}

#turns {
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;
}


#board-div {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
}

#board-div:after {
    content: "";
    display: block;
}

.field {
    display: grid;
    min-width: 8vh;
    min-height: 8vh;
    justify-items: center;
    align-items: center;
}

.field:after {
    content: "";
    display: block;
}

#numeric-div {
    position: absolute;
    margin-bottom: 6.1vh;
    margin-right: 6.5vh;
    font-size: 1.3vh;
}

.player-graveyard-wrapper {
    display: flex;
    flex-direction: column;
}

.player-graveyard-wrapper p {
    margin-left: .2vw;
}

.avatar {

}

#letter-div {
    position: absolute;
    margin-top: 6.1vh;
    margin-left: 6.5vh;
    font-size: 1.3vh;
}


.piece {
    cursor: pointer;
}

.piece-white {
    color: white;
}

.piece-black {
    color: black;
}


.piece svg {
    height: 7vh;
}

.graveyard-piece svg {
    height: 2vh;
}

.invisible {
    display: none;
}


.graveyard {
    margin-top: .5vh;
    margin-left: .2vh;
    display: flex;

}

.piece-graveyard {
    display: flex;
}



.legal {
    background-color: var(--color-chess-legal);
}

.last-move {
    background-color: var(--color-chess-last-move);
}

.take {
    background-color: var(--color-chess-take);
}

.hovered {
    background-color: var(--color-chess-hovered);
    cursor: pointer;
}

.tooltip {
    position: relative;
    text-decoration: underline dotted;
    cursor: help;
}

.tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    color: black;
    width: 60px;
    background: ghostwhite;
    border-radius: 5px;
    padding: 5px;
    font-size: .6em;
    z-index: 9000;

}

.tooltip::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid white;
}

.tooltip::before,
.tooltip::after {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out;
}

.tooltip:hover::before,
.tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}

.tooltip--top::before,
.tooltip--top::after {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: 15px;
}

.tooltip--top::after {
    margin-bottom: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid ghostwhite;
}


dialog {
    text-align: center;
    height: 200px;
    margin: auto;
    border: none;


}

dialog p {
    padding: .5em .5em .3em .5em;
    font-size: 4em;
    font-family: var(--font-family);
}



.modal::backdrop {
    background: gray;
    opacity: .5;
}

table {
    margin-top: 2vh;
    width: 100%;
    text-align: center;
    border-spacing: 0px;

}

td {
    padding: 1px 1px 1px 1px;
}

tr {
    color: white;
    font-size: 1.5vh;
    margin-left: 1vw;
    margin-right: 1vw;

}

.turn-even {
    background-color: #585160;
    opacity: 0.5;
}

.new-game-item {
    height: 3vh;
    margin-top: 1vh;
    margin-right: 1vw;
    margin-left: 1vw;
    width: 12vw;
    font-size: 1em;
}

#start-game-button{
    margin-top: 1vh;
    margin-right: 1vw;
    margin-left: 1vw;
    width: 12vw;
    font-size: 1em;

}

.piece-graveyard img {
    margin-right: -.5vw;
}


.chess-wrapper {
    display: flex;
    justify-content: center;
}


.chess-container {
    width: 50%;
    display: grid;
    justify-items: start;
    grid-template-columns: auto auto;
    grid-template-rows: auto 64vh auto;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    z-index: 5;
    font-family: var(--font-family);

}

#chess-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}

#chess-section {
    display: flex;
    justify-items: center;
    /*background-image: url("http://localhost:3000/chess_blur.jpg");*/
    background-image: url("/static/media/chess_blur.jpg");
    z-index: -2;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
}

#chess-section:before {
    content: "";
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 0;
    background-color: black;
    opacity: 0.3;

}

#panel {
    display: grid;
    grid-column: 2;
    grid-row: 2;
    z-index: 5;
}

#topPlayer {
    color: var(--color-text);
    align-self: end;
    grid-column: 1;
    grid-row: 1;
    z-index: 5;
}

#chess {
    grid-column: 1;
    grid-row: 2;
    z-index: 500;
}


input {
    background: var(--color-grey);
    color: white;
    box-shadow: none;
    padding: 5px;
    font-size: 1vh;
}


#new-game-wrapper {
    display: flex;
    flex-direction: column;

}


#bottomPlayer {
    color: var(--color-text);
    grid-row: 3;
    grid-column: 1;
    z-index: 5;

}

#bottomPlayer:after {
    content: "";
    display: block;
    position: absolute;
    background: white;
    opacity: .5;
}

.player-panel {
    height: 5vh;
    display: flex;

}

.player-name {
    font-size: 2vh;
    margin-left: 5px;
    color: white;
}

#new-game-balloon {
    grid-column: 3 / 5;
    position: absolute;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 500;
    height: 90px;
    width: 200px;
    border-radius: 0.2rem;
    font-size: 1em;
    align-self: end;
    padding: 5px;
    align-items: center;
    transform: translateX(14vw) translateY(-4.5vh);
}

#new-game-balloon p {
    font-family: var(--font-family);
    font-size: 1.2em;
    margin-bottom: .5em;
}

#buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-self: end;
    margin-top: .2vh;
    grid-column-gap: 5px;
}

#backButton {
    grid-column: 1 / 2;
}

#forwardButton {
    grid-column: 2 / 3;
}

#resetButton {
    grid-column: 3 / 4;
}

#newGameButton {
    grid-column: 4 / 5;
}

#button-wrapper {
    display: flex;
    gap: 10px;
}

#button-wrapper button {
    padding: 8px 8px 8px 8px;

}


.panel-button {
    color: white;
    font-size: 3vh;
    cursor: pointer;
    text-align: center;
}

.panel-button:hover {
    color: var(--color-yellow);
}

.arrow {
    display: inline-block;
    padding: 3px;
    cursor: pointer;
}

.reset-button {
    border-radius: 50%;
    border: 3px solid #000;
    cursor: pointer;
}



.invisible {
    display: none;
}

#piece-picker-box {
    display: grid;
    border: 1px solid var(--color-grey);
    background: var(--color-chess-black);
    z-index: 2;
    align-items: center;
    justify-items: center;
}

