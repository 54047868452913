.sticky-menu {
    background-color: white;
    top: 30px;
    z-index: 99;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    position: fixed;
    margin: 0 30px;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 20%);

}

.burger-icon:hover {
    color: var(--color-mauve);
}


.slide-out {
    opacity: 0;
    right: 0;
    transform: translateX(30px);
    transition: all 0.3s ease-in;
}


.slide-in {
    opacity: 1;
    right: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease-in;
}

.link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9vw;
    position: fixed;
    right: 0;
    margin-top: 20px;
    z-index: 99;
    opacity: 1;
    height: 220px;
    background-color: white;
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 20%);
    font-family: var(--font-family);
    transition: height 0.25s ease-in, opacity 0.5s ease-in;

}

.collapsed {
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-in;

}

.sticky-menu-item {
    opacity: 1;
    position: absolute;
    transition: all 0.3s ease-in;
}


.link-container p:first-child {
    top: 10px;
}

.link-container p:nth-child(2) {
    top: 60px;
}

.link-container p:nth-child(3) {
    top: 110px;
}

.link-container p:nth-child(4) {
    top: 160px;
}

.link-container.collapsed p {
    top: 0;

}

