.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.navigation-left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navigation-left_logo {
    margin-right: 2rem;
}

.navigation-left img {
    width: 60px;
    height: 60px;
}

.navigation-left-container {
    display: flex;
    flex-direction: row;
}

.navigation-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navigation-left-container p,
.navigation-sign p,
.navigation-menu-container p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 2em;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1em;
    cursor: pointer;
}


