@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;1,100;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background-color: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: var(--color-grey);
    color: #ffffff;
    font-family: var(--font-family);
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
    -webkit-transform 150ms ease;
    transition: background 250ms ease-in-out,
    transform 150ms ease;
    transition: background 250ms ease-in-out,
    transform 150ms ease,
    -webkit-transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover,
button:focus {
    background: var(--color-grey-shade);
}


button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

button:active {
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
}

button:hover {
    color: var(--color-yellow);
    cursor: pointer;
}

.link-effect {
    text-decoration: none;
    transition: all 300ms ease-in-out;
    transition-delay: 0s;
    border-bottom: 0 solid var(--color-mauve);
}

.link-effect:hover {
    cursor: pointer;
    color: white;
    box-shadow: inset 0 -1.2em 0 var(--color-mauve);
    transition-duration: 100ms;

}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-mauve);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--color-mauve);
}


h1 {
    color: var(--color-yellow);
    font-size: 4em;
}

h2 {
    color: white;
    font-size: 3vw;
    font-family: var(--font-family);
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
}


input, textarea, select {
    font-family: var(--font-family);
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}


.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.navigation-left {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navigation-left_logo {
    margin-right: 2rem;
}

.navigation-left img {
    width: 60px;
    height: 60px;
}

.navigation-left-container {
    display: flex;
    flex-direction: row;
}

.navigation-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navigation-left-container p,
.navigation-sign p,
.navigation-menu-container p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 2em;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1em;
    cursor: pointer;
}




.avatar img {
    height: 5vh;
}

.black {
    background-color: var(--color-chess-black);
    color: var(--color-chess-white);
}

.white {
    background-color: var(--color-chess-white);
    color: var(--color-chess-black);

}

select {
    background: var(--color-grey);
    color: #fff;
    text-align: center;
    font-size: 2vh;
    border: 1px solid var(--color-grey);
}

#turns {
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;
}


#board-div {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
}

#board-div:after {
    content: "";
    display: block;
}

.field {
    display: grid;
    min-width: 8vh;
    min-height: 8vh;
    justify-items: center;
    align-items: center;
}

.field:after {
    content: "";
    display: block;
}

#numeric-div {
    position: absolute;
    margin-bottom: 6.1vh;
    margin-right: 6.5vh;
    font-size: 1.3vh;
}

.player-graveyard-wrapper {
    display: flex;
    flex-direction: column;
}

.player-graveyard-wrapper p {
    margin-left: .2vw;
}

.avatar {

}

#letter-div {
    position: absolute;
    margin-top: 6.1vh;
    margin-left: 6.5vh;
    font-size: 1.3vh;
}


.piece {
    cursor: pointer;
}

.piece-white {
    color: white;
}

.piece-black {
    color: black;
}


.piece svg {
    height: 7vh;
}

.graveyard-piece svg {
    height: 2vh;
}

.invisible {
    display: none;
}


.graveyard {
    margin-top: .5vh;
    margin-left: .2vh;
    display: flex;

}

.piece-graveyard {
    display: flex;
}



.legal {
    background-color: var(--color-chess-legal);
}

.last-move {
    background-color: var(--color-chess-last-move);
}

.take {
    background-color: var(--color-chess-take);
}

.hovered {
    background-color: var(--color-chess-hovered);
    cursor: pointer;
}

.tooltip {
    position: relative;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    cursor: help;
}

.tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    color: black;
    width: 60px;
    background: ghostwhite;
    border-radius: 5px;
    padding: 5px;
    font-size: .6em;
    z-index: 9000;

}

.tooltip::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid white;
}

.tooltip::before,
.tooltip::after {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out;
}

.tooltip:hover::before,
.tooltip:hover::after {
    opacity: 1;
    visibility: visible;
}

.tooltip--top::before,
.tooltip--top::after {
    bottom: 100%;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    margin-bottom: 15px;
}

.tooltip--top::after {
    margin-bottom: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid ghostwhite;
}


dialog {
    text-align: center;
    height: 200px;
    margin: auto;
    border: none;


}

dialog p {
    padding: .5em .5em .3em .5em;
    font-size: 4em;
    font-family: var(--font-family);
}



.modal::-webkit-backdrop {
    background: gray;
    opacity: .5;
}



.modal::backdrop {
    background: gray;
    opacity: .5;
}

table {
    margin-top: 2vh;
    width: 100%;
    text-align: center;
    border-spacing: 0px;

}

td {
    padding: 1px 1px 1px 1px;
}

tr {
    color: white;
    font-size: 1.5vh;
    margin-left: 1vw;
    margin-right: 1vw;

}

.turn-even {
    background-color: #585160;
    opacity: 0.5;
}

.new-game-item {
    height: 3vh;
    margin-top: 1vh;
    margin-right: 1vw;
    margin-left: 1vw;
    width: 12vw;
    font-size: 1em;
}

#start-game-button{
    margin-top: 1vh;
    margin-right: 1vw;
    margin-left: 1vw;
    width: 12vw;
    font-size: 1em;

}

.piece-graveyard img {
    margin-right: -.5vw;
}


.chess-wrapper {
    display: flex;
    justify-content: center;
}


.chess-container {
    width: 50%;
    display: grid;
    justify-items: start;
    grid-template-columns: auto auto;
    grid-template-rows: auto 64vh auto;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    z-index: 5;
    font-family: var(--font-family);

}

#chess-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

}

#chess-section {
    display: flex;
    justify-items: center;
    /*background-image: url("http://localhost:3000/chess_blur.jpg");*/
    background-image: url("/static/media/chess_blur.jpg");
    z-index: -2;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
}

#chess-section:before {
    content: "";
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 0;
    background-color: black;
    opacity: 0.3;

}

#panel {
    display: grid;
    grid-column: 2;
    grid-row: 2;
    z-index: 5;
}

#topPlayer {
    color: var(--color-text);
    align-self: end;
    grid-column: 1;
    grid-row: 1;
    z-index: 5;
}

#chess {
    grid-column: 1;
    grid-row: 2;
    z-index: 500;
}


input {
    background: var(--color-grey);
    color: white;
    box-shadow: none;
    padding: 5px;
    font-size: 1vh;
}


#new-game-wrapper {
    display: flex;
    flex-direction: column;

}


#bottomPlayer {
    color: var(--color-text);
    grid-row: 3;
    grid-column: 1;
    z-index: 5;

}

#bottomPlayer:after {
    content: "";
    display: block;
    position: absolute;
    background: white;
    opacity: .5;
}

.player-panel {
    height: 5vh;
    display: flex;

}

.player-name {
    font-size: 2vh;
    margin-left: 5px;
    color: white;
}

#new-game-balloon {
    grid-column: 3 / 5;
    position: absolute;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 500;
    height: 90px;
    width: 200px;
    border-radius: 0.2rem;
    font-size: 1em;
    align-self: end;
    padding: 5px;
    align-items: center;
    -webkit-transform: translateX(14vw) translateY(-4.5vh);
            transform: translateX(14vw) translateY(-4.5vh);
}

#new-game-balloon p {
    font-family: var(--font-family);
    font-size: 1.2em;
    margin-bottom: .5em;
}

#buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-self: end;
    margin-top: .2vh;
    grid-column-gap: 5px;
}

#backButton {
    grid-column: 1 / 2;
}

#forwardButton {
    grid-column: 2 / 3;
}

#resetButton {
    grid-column: 3 / 4;
}

#newGameButton {
    grid-column: 4 / 5;
}

#button-wrapper {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

#button-wrapper button {
    padding: 8px 8px 8px 8px;

}


.panel-button {
    color: white;
    font-size: 3vh;
    cursor: pointer;
    text-align: center;
}

.panel-button:hover {
    color: var(--color-yellow);
}

.arrow {
    display: inline-block;
    padding: 3px;
    cursor: pointer;
}

.reset-button {
    border-radius: 50%;
    border: 3px solid #000;
    cursor: pointer;
}



.invisible {
    display: none;
}

#piece-picker-box {
    display: grid;
    border: 1px solid var(--color-grey);
    background: var(--color-chess-black);
    z-index: 2;
    align-items: center;
    justify-items: center;
}


.about-me-section {
    display: flex;
    font-family: var(--font-family);
    height: 120vh;
    /*background-image: url("http://localhost:3000/computer_background.jpg");*/
    background-image: url("/static/media/computer_background.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;

}


.about-me-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
}


.about-me-text {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    padding: 5rem 5rem 5rem 5rem;
    font-family: var(--font-family-text);
    font-weight: lighter;
    line-height: 35px;
    color: white ;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-animation: blur-in 500ms 200ms forwards;
            animation: blur-in 500ms 200ms forwards;
    border: 1px solid rgba(255, 255, 255, 0.3);

}


.text-block {
    padding: 5rem;
}


.opacity-transition-out {
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

.about-me-content {
    display: grid;
    grid-template-rows: auto auto auto;
    height: 100%;
    width: 100%;
    font-family: var(--font-family);
    margin-right: 1em;
    margin-left: 1em;
}

.content-and-headline {
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 2 / 3;
    height: 100%;
    width: 100%;
    font-family: var(--font-family);

}

#content-box {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.icons {
    margin-right: 20px;
    margin-bottom: 10px;
    grid-row: 2;
    margin-top: 1rem;
    align-self: start;
}

.icon {
    border: 1px solid var(--color-grey-shade);
    padding: 3px 6px 0 6px;
    color: var(--color-mauve);
    font-size: 4vh;
    margin-right: 6px;
    cursor: pointer;
}

.icon:hover {
    color: var(--color-yellow);
    border: 1px solid var(--color-yellow);
}


.sidebar {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
}


.about-me-image img {
    height: 25vw;
}


.content-headline {
    font-size: 5vw;
    color: white;
    height: 15vh;
    padding-top: 1rem;
    padding-left: 5rem;
    border-top:  1px solid white;
    z-index: 20;
    font-weight:lighter;

}


.menu {
    display: flex;
    grid-row: 1;
    flex-direction: row;
    align-items: end;
}

.menu-element {
    background-color: var(--color-grey);
    color: white;
    font-family: var(--font-family);
    font-size: calc(1.5vw + .7vh);
    font-weight: lighter;
    width: 20%;
    padding-right: 2vw;
    padding-left: .5vw;
    padding-bottom: 5px;
    height: 90%;
}

.menu-active {
    height: 100%;
    color: white;
    font-weight: normal;
    box-shadow: 3px 3px 5px 0px var(--color-grey);

}

.headline-wrapper {
    display: grid;
    height: 100%;
    width: 10vw;
    justify-content: start;
}

.headline-wrapper p {
    font-size: 10vh;
    text-orientation: upright;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    text-transform: uppercase;

}

.headline {
    font-weight: lighter;
    color: white;
    height: 100%;
}

.headline-background {
    grid-row: 2;
    grid-column: 1;
    height: 15vh;
    z-index: 0;
    opacity: .5;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    -webkit-animation: blur-in 500ms 200ms forwards;
            animation: blur-in 500ms 200ms forwards;

}


.me {
    color: var(--color-mauve);
    font-weight: lighter;
}



.opacity-transition-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;

}


.menu-background-1 {
    background-color: var(--color-menu-1);

}

.menu-background-2 {
    background-color: var(--color-menu-2);
}

.menu-background-3 {
    background-color: var(--color-menu-3);
}

.about-me-text p {
    margin-top: 2rem;
    font-weight: lighter;
    font-size: 1.5rem;
}

.dot {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin: 0 2px;
    background-color: var(--color-mauve);
    border-radius: 50%;
    border: 1px solid white;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot:hover {
    background-color: var(--color-chess-legal);

}

.dot-active {
    background-color: var(--color-yellow);
}


.slideshow-container {
    height: 70vh;
    width: 60vw;
    display: grid;
    grid-template-rows: 10% 80% 10%;
    grid-template-columns: 10% 80% 10%;
}

.hidden {
    height: 0;
    width: 0;
    opacity: 0;
}


.project-container {
    height: 100%;
    display: grid;
    grid-template-rows: 20% 40% 20% 20%;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: start;
    justify-items: start;
}

.project-container p {
    font-style: italic;
    font-size: 1.2vw;
}

.project-container div {
    margin-top: 2em;
}

.project-container h3 {
    font-size: 1.5vw;
    margin-bottom: .5em;

}

.slide-container {
    display: grid;
    overflow: hidden;
    grid-row: 2;
    grid-column: 2;
    align-items: center;
    box-shadow: 3px 3px 5px 0px rgb(51, 46, 46);;
}


.slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    padding: 2rem 2rem 2rem 2rem;
    background-color: white;
    color: black;

}

.slide h2 {
    color: black;
    border-bottom: 1px solid black;
}

.slider-arrow {
    align-self: center;
    justify-self: center;
    cursor: pointer;
    z-index: 99;
    font-size: 2vw;
    color: white;


}

.slider-arrow:hover {
    color: var(--color-yellow);
}


.slide-left {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all .5s ease-in-out;
}


.slide-right {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: all .5s ease-in;
}

.there {
    grid-row: 1;
    grid-column: 1;
    z-index: 50;
}

.not-there {
    opacity: 0;
    grid-row: -1;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 0;
}

.start-right {
    right: 100%;
}

.start-left {
    left: 100%;
}


#slider-arrow-right {
    grid-column: 3;
    grid-row: 2;

}

#slider-arrow-left {
    grid-column: 1;
    grid-row: 2;
}


.dots {
    align-self: center;
    justify-self: center;
    grid-row: 3;
    grid-column: 2;
}

.experience-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    padding-top: 1rem;

}

.experience-container-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    opacity: .9;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    -webkit-animation: blur-in 500ms 200ms forwards;
            animation: blur-in 500ms 200ms forwards;
}

@-webkit-keyframes blur-in {
    from {
        backdrop-filter:brightness(100%) blur(0px);
        -webkit-backdrop-filter: blur(5px);
    }
    to {
        backdrop-filter:brightness(90%) blur(7px);
        -webkit-backdrop-filter: blur(5px);
    }
}

@keyframes blur-in {
    from {
        backdrop-filter:brightness(100%) blur(0px);
        -webkit-backdrop-filter: blur(5px);
    }
    to {
        backdrop-filter:brightness(90%) blur(7px);
        -webkit-backdrop-filter: blur(5px);
    }
}


.experience-item {
    margin-bottom: 3rem;
    font-size: 1vw;

}

#work-div {
    margin-right: 1em;
}

.experience-item p{
    margin-left: 1rem;
}


.experience-item h3 {
    margin-bottom: 2rem;
    color: white;
    font-family: var(--font-family);
    font-weight: bold;
    border-bottom: 1px solid white;
}

.experience-item h4 {
    margin-bottom: .5rem;
    margin-left: .5rem;
}

.link {
    text-decoration: underline;
}
@media only screen and (max-width: 1024px) {

    .headline-wrapper p {
        font-size: 6rem;

    }

}


@media only screen and (max-width: 1024px) {

    .headline-wrapper p {
        font-size: 6rem;
    }

}

@media only screen and (max-width: 768px) {

    .headline-wrapper p {
        font-size: 5vh;

    }

}

@media only screen and (max-height: 950px) {

    .headline-wrapper p {
        font-size: 5rem;

    }
    .experience-item  {
        margin-bottom: 1rem;
    }
    .experience-container h2 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 2em;
    }
    .experience-container h3 {
        margin-top: 0;
        margin-bottom: .5rem;
    }
    .experience-container p {
        margin-bottom: 1rem;
        font-size: 1.2em;
    }
}


@media only screen and (max-height: 640px) {
    .headline-wrapper p {
        font-size: 4em;
     }
}


@media only screen and (max-height: 1030px) {
    .about-me-text p {
        font-size: 1.2em;
        margin-top: 0.5em;

    }
    .about-me-text h2 {
        font-size: 2.5em;
    }
    .about-me-text {
        padding: 0 1.5em .5em;
    }
}

@media only screen and (max-height: 800px) {
    .about-me-text p {
        font-size: .9em;
    }
    .about-me-text h2 {
        font-size: 1.7em;
    }
}





#display-div {
    display: flex;

}

#display {
    margin-top: 15vh;
    margin-bottom: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#arrow-down {
    margin-top: 20vh;
    font-size: 4rem;
    color: var(--color-grey);
    cursor: pointer;

}

#arrow-down:hover {
    color: var(--color-yellow);
}


.letter-box {
    display: grid;
    margin: 1rem;
}

.pixel {
    margin: 1px;
    opacity: 0;
    transition: all 1s ease;
    height: 5vh;
    width: 5vh;

}

.pixel:hover {
    background-color: var(--color-mauve);
}

.fade {
    background-color: var(--color-yellow);
    opacity: 1;
}

.move {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
    opacity: 0;
}

.enter {
    background-color: var(--color-dark-lila);
    cursor: pointer;
}


@-webkit-keyframes blink {
    15% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
    50% {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes blink {
    15% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }
    50% {
        -webkit-transform: translateX(3px);
        transform: translateX(3px);
    }
    65% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    80% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.blink {
    -webkit-animation: blink 2s ease;
    animation: blink 2s ease;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}



#contact {
    margin-top: 15vh;
    display: flex;
    justify-items: center;
    justify-content: center;
    color: var(--color-text);
    font-family: var(--font-family);
    background-color: white;
    height: 80vh;
}

label {
    margin-bottom: 1vh;
    margin-top: 1vh;
}

textarea {
    height: 40vh;
    width: 40vw;
    resize: none;
    font-size: 1em;
    margin-bottom: 1vh;
    font-family: var(--font-family);
    padding: 5px 5px 5px 5px;

}

#contact input{
    width: 40vw;
    background-color: white;
    border: 1px solid var(--color-grey);
    color: var(--color-text);
    font-size: 1em;
}

#contact-form {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 10vh;
}

.thank-you-div {
    font-family: var(--font-family);
    margin-top: 10vh;
    margin-bottom: 10vh;
    font-size: 5vh;
    align-self: center;
    color: black;
}


footer {
    display: flex;
    flex-direction: column;
    color: var(--color-grey);
    font-family: var(--font-family);
    font-size: 1em;
    background-color: var(--color-bg);
    height: 5vh;
    justify-content: center;
    align-items: center;

}

.sticky-menu {
    background-color: white;
    top: 30px;
    z-index: 99;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    position: fixed;
    margin: 0 30px;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 20%);

}

.burger-icon:hover {
    color: var(--color-mauve);
}


.slide-out {
    opacity: 0;
    right: 0;
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    transition: all 0.3s ease-in;
}


.slide-in {
    opacity: 1;
    right: 0;
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
    transition: all 0.3s ease-in;
}

.link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9vw;
    position: fixed;
    right: 0;
    margin-top: 20px;
    z-index: 99;
    opacity: 1;
    height: 220px;
    background-color: white;
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 20%);
    font-family: var(--font-family);
    transition: height 0.25s ease-in, opacity 0.5s ease-in;

}

.collapsed {
    height: 0;
    opacity: 0;
    transition: all 0.3s ease-in;

}

.sticky-menu-item {
    opacity: 1;
    position: absolute;
    transition: all 0.3s ease-in;
}


.link-container p:first-child {
    top: 10px;
}

.link-container p:nth-child(2) {
    top: 60px;
}

.link-container p:nth-child(3) {
    top: 110px;
}

.link-container p:nth-child(4) {
    top: 160px;
}

.link-container.collapsed p {
    top: 0;

}


.chess-display {
    height: 40vh;
    background-color: var(--color-grey);
}

.letter-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

letter-container.letter-box div {
    background-color: white;
}

.chess-pixel {
    border: 1px solid var(--color-mauve);
    opacity: 1;
}
.about-me-mobile-section {
    /*background-image: url("http://localhost:3000/computer_background.jpg");*/
    background-image: url("/static/media/computer_background.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    height: 100svh;
}

.about-me-mobile-container {
    height: 90vh;
    height: 90svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 2em 2em 2em;
    overflow: scroll;
}

.about-me-image img{
    height: 30vh;
}


.about-me-text-mobile {
    display: flex;
    padding: .5em .5em .5em .5em;
    flex-direction: column;
    font-family: var(--font-family-text);
    font-weight: lighter;
    font-size: 1em;
    color: white ;

}

.content-box-mobile {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-animation: blur-in 500ms 200ms forwards;
            animation: blur-in 500ms 200ms forwards;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter:brightness(90%) blur(7px);
    -webkit-backdrop-filter: blur(5px);
}

:root {
    --font-family: Work Sans, sans-serif;
    --font-family-text: Roboto;
    --color-bg: white;
    --color-mauve: #b8a89a;
    --color-text: #000000;
    --color-grey: #2e2e30;
    --color-grey-shade: #989898;
    --color-dark-lila: #5E4B5E;
    --color-light-lila: #A16477;
    --color-yellow: #ffc155;

    --color-chess-white: #f0d9b5;
    --color-chess-black: #b58863;

    --color-chess-take: #9C4A59;
    --color-chess-legal: #CED570;
    --color-chess-last-move: #ffc155;
    --color-chess-hovered: #F2FEDC;

    --color-menu-1: #5E4B5E;
    --color-menu-2: #A16477;
    --color-menu-3: #DF8576;


}
