.experience-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    padding-top: 1rem;

}

.experience-container-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
    opacity: .9;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: blur-in 500ms 200ms forwards;
}

@keyframes blur-in {
    from {
        backdrop-filter:brightness(100%) blur(0px);
        -webkit-backdrop-filter: blur(5px);
    }
    to {
        backdrop-filter:brightness(90%) blur(7px);
        -webkit-backdrop-filter: blur(5px);
    }
}


.experience-item {
    margin-bottom: 3rem;
    font-size: 1vw;

}

#work-div {
    margin-right: 1em;
}

.experience-item p{
    margin-left: 1rem;
}


.experience-item h3 {
    margin-bottom: 2rem;
    color: white;
    font-family: var(--font-family);
    font-weight: bold;
    border-bottom: 1px solid white;
}

.experience-item h4 {
    margin-bottom: .5rem;
    margin-left: .5rem;
}

.link {
    text-decoration: underline;
}