.dot {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin: 0 2px;
    background-color: var(--color-mauve);
    border-radius: 50%;
    border: 1px solid white;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot:hover {
    background-color: var(--color-chess-legal);

}

.dot-active {
    background-color: var(--color-yellow);
}


.slideshow-container {
    height: 70vh;
    width: 60vw;
    display: grid;
    grid-template-rows: 10% 80% 10%;
    grid-template-columns: 10% 80% 10%;
}

.hidden {
    height: 0;
    width: 0;
    opacity: 0;
}


.project-container {
    height: 100%;
    display: grid;
    grid-template-rows: 20% 40% 20% 20%;
    flex-direction: column;
    gap: 1rem;
    align-items: start;
    justify-items: start;
}

.project-container p {
    font-style: italic;
    font-size: 1.2vw;
}

.project-container div {
    margin-top: 2em;
}

.project-container h3 {
    font-size: 1.5vw;
    margin-bottom: .5em;

}

.slide-container {
    display: grid;
    overflow: hidden;
    grid-row: 2;
    grid-column: 2;
    align-items: center;
    box-shadow: 3px 3px 5px 0px rgb(51, 46, 46);;
}


.slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: relative;
    padding: 2rem 2rem 2rem 2rem;
    background-color: white;
    color: black;

}

.slide h2 {
    color: black;
    border-bottom: 1px solid black;
}

.slider-arrow {
    align-self: center;
    justify-self: center;
    cursor: pointer;
    z-index: 99;
    font-size: 2vw;
    color: white;


}

.slider-arrow:hover {
    color: var(--color-yellow);
}


.slide-left {
    transform: translateX(-100%);
    transition: all .5s ease-in-out;
}


.slide-right {
    transform: translateX(100%);
    transition: all .5s ease-in;
}

.there {
    grid-row: 1;
    grid-column: 1;
    z-index: 50;
}

.not-there {
    opacity: 0;
    grid-row: -1;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 0;
}

.start-right {
    right: 100%;
}

.start-left {
    left: 100%;
}


#slider-arrow-right {
    grid-column: 3;
    grid-row: 2;

}

#slider-arrow-left {
    grid-column: 1;
    grid-row: 2;
}


.dots {
    align-self: center;
    justify-self: center;
    grid-row: 3;
    grid-column: 2;
}
